import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const NotFoundPage: React.FC = () => (
  <Layout>
    <Head title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Kinda not wow.</p>
  </Layout>
)

export default NotFoundPage
